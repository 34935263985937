(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@wix/image-kit"), require("react"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["imageClientApi", "react", "reactDOM", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["PostViewerWidgetNoCss"] = factory(require("@wix/image-kit"), require("react"), require("react-dom"), require("lodash"));
	else
		root["PostViewerWidgetNoCss"] = factory(root["__imageClientApi__"], root["React"], root["ReactDOM"], root["_"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__43425__, __WEBPACK_EXTERNAL_MODULE__60751__, __WEBPACK_EXTERNAL_MODULE__83202__, __WEBPACK_EXTERNAL_MODULE__16252__) => {
return 